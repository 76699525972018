<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Abrir" }} Chamados
          </h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')">
            <div class="form-group row">
              <div class="col-md-2">
                <label class="col-md-12">Prioridade:*</label>
                <select
                  required=""
                  v-model="form.prioridade"
                  class="form-control"
                >
                  <option :value="null">Selecione</option>
                  <option value="1">Alta</option>
                  <option value="2">Média</option>
                  <option value="3">Baixa</option>
                </select>
              </div>
              <div class="col-md-2">
                <label class="col-md-12">Tipo Suporte:*</label>
                <select required="" v-model="form.tipo" class="form-control">
                  <option :value="null">Selecione</option>
                  <option value="1">Técnico/Sistema</option>
                  <option value="2">Financeiro/administração</option>
                </select>
              </div>
              <div  class="col-md-2">
                <label class="col-md-12">status:*</label>
                <select required="" v-model="form.status" class="form-control">
                  <option :value="null">Selecione</option>
                  <option value="1">fechado</option>
                  <option value="2">aberto</option>

                  
                </select>
              </div>

              <div class="col-md-6">
                <label class="col-md-12">Assunto:*</label>
                <textarea
                  required
                  type="text"
                  class="form-control"
                  v-model="form.assunto"
                  placeholder="Digite o assunto..."
                />
              </div>
              <div v-if="this.lista_campos == ''" class="col-md-12">
                <label class="col-md-12">Mensagem:*</label>
                <textarea
                  required
                  rows="5"
                  type="text"
                  class="form-control"
                  v-model="form.mensagem"
                  placeholder="Digite o mensagem..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                type="submit"
               
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        tipo: null,
        prioridade: null,
        status: 2,
        assunto: "",
        mensagem: "",
      },
      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.chamados.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma Chamado no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("chamados/create_chamados", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "chamados",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("chamados/update_chamados", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "chamados",
      });
    },

    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          tipo: this.lista_campos.tipo,
          prioridade: this.lista_campos.prioridade,
          status: this.lista_campos.status,
          assunto: this.lista_campos.assunto,
      
          status: this.lista_campos.status,
          user_in: this.lista_campos.user_in,
          user_up: this.lista_campos.user_up,
          filial_id: this.lista_campos.filial_id,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>