import { render, staticRenderFns } from "./createChamados.vue?vue&type=template&id=685dd29b&scoped=true&"
import script from "./createChamados.vue?vue&type=script&lang=js&"
export * from "./createChamados.vue?vue&type=script&lang=js&"
import style0 from "./createChamados.vue?vue&type=style&index=0&id=685dd29b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685dd29b",
  null
  
)

export default component.exports